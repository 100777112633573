// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

// npm packages
import $ from 'jquery';
import anchorScroll from 'anchor-scroll';


// requestAnimationFrame
window.requestAnimationFrame = ((() => window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  (callback => {
    window.setTimeout(callback, 1000 / 60);
  })))();
// Configs
const Configs = {
  backgroundColor: '#eee9e9',
  particleNum: 1000,
  step: 5,
  base: 1000,
  zInc: 0.001
};


// Vars
let canvas;

let context;
let screenWidth;
let screenHeight;
let centerX;
let centerY;
const particles = [];
let hueBase = 0;
let simplexNoise;
let zoff = 0;
let gui;


// Initialize
function init() {
  canvas = document.getElementById('effect');

  window.addEventListener('resize', onWindowResize, false);
  onWindowResize(null);

  for (let i = 0, len = Configs.particleNum; i < len; i++) {
    initParticle((particles[i] = new Particle()));
  }

  simplexNoise = new SimplexNoise();

  canvas.addEventListener('click', onCanvasClick, false);

  gui = new dat.GUI();
  gui.add(Configs, 'step', 1, 10);
  gui.add(Configs, 'base', 500, 3000);
  gui.add(Configs, 'zInc', 0.0001, 0.01);
  gui.close();

  update();
}

// Event listeners
function onWindowResize(e) {
  screenWidth = canvas.width = window.innerWidth;
  screenHeight = canvas.height = window.innerHeight;

  centerX = screenWidth / 2;
  centerY = screenHeight / 2;

  context = canvas.getContext('2d');
  context.lineWidth = 0.3;
  context.lineCap = context.lineJoin = 'round';
}

function onCanvasClick(e) {
  context.save();
  context.globalAlpha = 0.8;
  context.fillStyle = Configs.backgroundColor;
  context.fillRect(0, 0, screenWidth, screenHeight);
  context.restore();

  simplexNoise = new SimplexNoise();
}

// Functions
function getNoise(x, y, z) {
  const octaves = 4;
  const fallout = 0.5;
  let amp = 1;
  let f = 1;
  let sum = 0;
  let i;

  for (i = 0; i < octaves; ++i) {
    amp *= fallout;
    sum += amp * (simplexNoise.noise3D(x * f, y * f, z * f) + 1) * 0.5;
    f *= 2;
  }

  return sum;
}

function initParticle(p) {
  p.x = p.pastX = screenWidth * Math.random();
  p.y = p.pastY = screenHeight * Math.random();
  p.color.h = hueBase + Math.atan2(centerY - p.y, centerX - p.x) * 180 / Math.PI;
  p.color.s = 1;
  p.color.l = 0.5;
  p.color.a = 0;
}

// Update
function update() {
  const step = Configs.step;
  const base = Configs.base;
  let i;
  let p;
  let angle;

  for (i = 0, len = particles.length; i < len; i++) {
    p = particles[i];

    p.pastX = p.x;
    p.pastY = p.y;

    angle = Math.PI * 6 * getNoise(p.x / base * 1.75, p.y / base * 1.75, zoff);
    p.x += Math.cos(angle) * step;
    p.y += Math.sin(angle) * step;

    if (p.color.a < 1) p.color.a += 0.003;

    context.beginPath();
    context.strokeStyle = p.color.toString();
    context.moveTo(p.pastX, p.pastY);
    context.lineTo(p.x, p.y);
    context.stroke();

    if (p.x < 0 || p.x > screenWidth || p.y < 0 || p.y > screenHeight) {
      initParticle(p);
    }
  }

  hueBase += 0.1;
  zoff += Configs.zInc;

  requestAnimationFrame(update);
}


// HSLA
class HSLA {
  constructor(h, s, l, a) {
    this.h = h || 0;
    this.s = s || 0;
    this.l = l || 0;
    this.a = a || 0;
  }

  toString() {
    return `hsla(${this.h},${this.s * 100}%,${this.l * 100}%,${this.a})`;
  }
}

// Particle
function Particle(x, y, color) {
  this.x = x || 0;
  this.y = y || 0;
  this.color = color || new HSLA();
  this.pastX = this.x;
  this.pastY = this.y;
}


$(() => {
  anchorScroll.init({
    updateUrl: false,
    duration: 600,
    offset: 0,
    ease: 'out-cube',
  });

  init();
});
